import React from 'react';
import '../CSS/button.css';


 
function Button( {btn_value  } ) {
    return (
    
        <button    >{btn_value}</button>
     
    )
}

export default Button ;

 