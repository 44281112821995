import React from 'react';
import '../CSS/loder.css'; 
import animoder from '../img/apple.gif'
function Loder() {
    return (
        <>
            <div className="loder">
            
            <img src={animoder} alt="Loading......" />
        </div>
        </>
    )
}

export default Loder ;
